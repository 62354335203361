/* You can add global styles to this file, and also import other style files */
@import 'assets/scss/theme/variables';
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

.ck-editor__editable_inline {
  min-height: 300px !important;
}
@media all and (display-mode: fullscreen) {
  .ck-editor__editable_inline {
    height: 100vh !important;
  }
}

/* Your custom CSS */
/* Add Bootstrap-like styles to PrimeNG Autocomplete */
.p-autocomplete {
  width: 100%;
  position: relative;
}

/* Input box styles */
.p-autocomplete .p-inputtext {
  height: calc(1.5em + 0.75rem + 2px); /* Bootstrap form-control height calculation */
  padding: 0.375rem 2.25rem 0.375rem 0.75rem; /* Adjust padding to accommodate the arrow button */
  margin: 0; /* Reset margin */
  border: 1px solid #ced4da; /* Bootstrap input border */
  border-radius: 0.25rem; /* Bootstrap input border-radius */
}

/* Arrow button styles */
.p-autocomplete .p-autocomplete-dropdown {
  top: calc(100% - calc(1.5em + 0.75rem + 2px)); /* Position the arrow button correctly */
  right: 0.75rem; /* Adjust right spacing for the arrow button */
  height: calc(1.5em + 0.7rem); /* Set the height to match the form control height */
  display: flex;
  align-items: center;
  width: 2rem !important;
}

.p-autocomplete .p-autocomplete-dropdown .p-autocomplete-dropdown-icon {
  width: 1rem; /* Set a specific width for the arrow icon */
  height: 1rem; /* Set a specific height for the arrow icon */
}

.p-autocomplete .p-inputtext:focus {
  border-color: #80bdff; /* Bootstrap input focus border color */
  outline: 0; /* Bootstrap input focus outline */
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Bootstrap input focus box-shadow */
}

.p-autocomplete-panel {
  margin-top: 2px; /* Adjust the panel margin */
  width: 100%; /* Make the panel match the input width */
  max-height: 150px; /* Limit the panel height */
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid #ccc; /* Bootstrap panel border */
  border-radius: 0.25rem; /* Bootstrap panel border-radius */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15); /* Bootstrap panel box-shadow */
  background-color: #fff; /* Bootstrap panel background color */
}
.item-quick-options label {
  display: none;
}

.table-clear-icon {
  width: 30px;
}

.rtl .text-end {
  text-align: left !important;
}
.rtl .p-datatable .p-datatable-tbody > tr > td {
  text-align: right !important;
}

.rtl .p-datatable .p-datatable-thead > tr > th {
  text-align: right !important;
}

/* RTL Styles for p-paginator */
.rtl .p-paginator {
  /* Flip layout */
  direction: rtl;

  /* Adjust text alignment for RTL */
  text-align: right;

  /* If necessary, adjust margin or padding for proper alignment */
  margin: 0; /* Example margin adjustment */
}

/* Ensure proper alignment for paginator elements */
.rtl .p-paginator .pagination-link {
  /* Adjust styles for the pagination links */
  /* Example styles: */
  padding: 5px 10px;
  margin: 0 5px;
}

.rtl angledoublelefticon,
.rtl angledoublerighticon,
.rtl anglelefticon,
.rtl anglerighticon {
  transform: rotate(180deg);
}
.rtl .p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 16px;
  padding-left: 0px;
}

@media (max-width: 500px) {
  .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-prev,
  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-last {
    min-width: 0.1rem !important;
  }

  .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    background: unset !important;
    border-color: unset;
  }
}
.p-calendar {
  width: 100%;
}
.p-multiselect.p-component.p-multiselect-chip {
  max-width: 300px;
}

.rtl .p-autocomplete-input {
  text-align: right;
}

.rtl .p-autocomplete-dropdown {
  left: auto;
  right: 0;
}
.rtl .p-element.p-ripple.p-autocomplete-dropdown.p-button-icon-only.p-button.p-component {
  /* If the icon is an image, you can use a CSS transform to flip it */
  transform: scaleX(-1);

  /* Adjust the positioning */
  left: 0;
  right: auto;
}
.rtl .p-element.p-ripple.p-autocomplete-dropdown.p-button-icon-only.p-button.p-component {
}

.rtl .p-autocomplete-dd .p-autocomplete-input,
.p-autocomplete-dd .p-autocomplete-multiple-container {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ppnone > tbody > tr > .p-treenode {
  padding-right: 0px !important;
}

.rtl .ppnone > tbody > tr > .p-treenode {
  padding-left: 0px !important;
  padding-right: 45px !important;
}

.table-overflow {
  overflow-x: auto;
  background: #ffffff;
  box-shadow: 0px 2px 10px 4px rgb(0 0 0 / 25%);
  border-radius: 10px 10px 10px 10px;
}

.table-overflow::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #e8e4e4;
}

.table-overflow::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

.table-overflow::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #302e2e;
}
.rtl .mdc-label {
  margin-right: 5px;
}

.rtl .nav-right > ul > li {
  text-align: left !important;
}

.rtl .p-autocomplete .p-inputtext {
  text-align: right;
}
.rtl .p-autocomplete .p-inputtext {
  padding: 0.375rem 0.75rem 0.375rem 2.25rem;
}
.editor-label {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.ck.ck-powered-by {
  display: none !important;
}
.ck-restricted-editing_mode_standard.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
  height: 300px;
  overflow: auto;
}
.logo-wrapper a {
  margin: auto;
}

.p-button {
  color: #ffffff;
  background: #202020 !important;
  border: 1px solid #202020 !important;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
}
.nav-link {
  color: $primary-color !important;
}
.nav-link.active {
  color: #000 !important;
}

.apexcharts-tooltip-marker {
  background-color: #202020 !important;
}

/* Apply Bootstrap-like styling to p-chips */
.p-chips {
  width: 100%;
  display: inline-flex !important ;
  flex-wrap: wrap !important ;
  align-items: center !important ;
  padding: 0rem 0rem !important;
  font-size: 1rem !important ;
  line-height: 1.5 !important ;
  color: #495057 !important ;
  background-color: #fff !important ;
  border-radius: 0.25rem !important ;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important ;
}

.p-chips .p-chips-token {
  margin: 0.125rem !important ;
  padding: 0.25rem 0.5rem !important ;
  font-size: 1rem !important ;
  line-height: 1.5 !important ;
  color: #fff !important ;
  background-color: #202020 !important ;
  border-radius: 0.2rem !important ;
}

.p-chips .p-chips-token .p-chips-token-icon {
  font-size: 1rem !important;
  cursor: pointer !important;
}

/* Apply Bootstrap-like styling to p-chips input field */
.p-chips .p-chips-input-token {
  font-size: 1rem !important;
  line-height: 1.5 !important;
}

/* Apply Bootstrap-like styling to p-chips input field */
.p-chips .p-chips-input-wrapper {
  flex: 1 !important;
}

/* Apply Bootstrap-like styling to p-chips input field */
.p-chips .p-chips-input-wrapper input {
  border: 0 !important;
  outline: 0 !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  color: #495057 !important;
  background-color: #fff !important;
  border-radius: 0.25rem !important;
  box-shadow: inset 0 0 0 transparent !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

/* Apply Bootstrap-like styling to p-chips input field when focused */
.p-chips .p-chips-input-wrapper input:focus {
  border-color: #80bdff !important;
  box-shadow: 0 0 0 0.2rem #2020203b !important;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #202020 !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #2020203b;
}

.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #2020203b !important;
  border-color: #202020 !important;
}

.p-chips .p-chips-multiple-container {
  width: 100%;
}

.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
  border-color: #202020 !important;
}
mat-chip-grid {
  border: 1px solid #dee2e6;
  border-radius: 5px;
  min-height: 38.5px;
  padding-left: 8px;
  padding-right: 8px;
}
.p-toast-top-center > * {
  z-index: 999999999999999;
}
